/* CSS Complementario
-------------------------------------------------------------- */	
.flat-row {
    overflow: hidden;
}

#mensaje {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

#octopusWidget {
  position: absolute;
  opacity: 0;
  -webkit-transform: translate3d(0, -170%, 0);
          transform: translate3d(0, -170%, 0);
}

#octopusWidget label {
    color: #fff;
    margin-bottom: 6px;
}

#octopusWidget h1, 
#octopusWidget h2, 
#octopusWidget h3, 
#octopusWidget h4, 
#octopusWidget h5, 
#octopusWidget h6 {
    color: #fff;
    margin-bottom: 24px;
}

#octopusWidget select,
#octopusWidget textarea,
#octopusWidget input[type="text"],
#octopusWidget input[type="password"],
#octopusWidget input[type="datetime"],
#octopusWidget input[type="datetime-local"],
#octopusWidget input[type="date"],
#octopusWidget input[type="month"],
#octopusWidget input[type="time"],
#octopusWidget input[type="week"],
#octopusWidget input[type="number"],
#octopusWidget input[type="email"],
#octopusWidget input[type="url"],
#octopusWidget input[type="search"],
#octopusWidget input[type="tel"],
#octopusWidget input[type="color"] {
  height: 35px;
  border-radius: 4px;
  margin-bottom: 0;
  color: #555;
}

#octopusWidget button[type="submit"] {
    height: 38px;
    margin-top: 31px !important;
}

.fade-out {
  -webkit-animation: fadeOut 1s forwards ease;
          animation: fadeOut 1s forwards ease;
}
@-webkit-keyframes fadeOut {
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -120%, 0);
            transform: translate3d(0, -120%, 0);
  }
}
@keyframes fadeOut {
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -120%, 0);
            transform: translate3d(0, -120%, 0);
  }
}
.fade-in {
  -webkit-animation: fadeIn 1s forwards ease;
          animation: fadeIn 1s forwards ease;
}
@-webkit-keyframes fadeIn {
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, -93%, 0);
            transform: translate3d(0, -93%, 0);
    transition: all .5s ease;
  }
}
@keyframes fadeIn {
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, -93%, 0);
            transform: translate3d(0, -93%, 0);
    transition: all .5s ease;
  }
}